<div [attr.aria-label]="accessibilityConfig?.loadingSpinnerAriaLabel"
     [title]="accessibilityConfig?.loadingSpinnerTitle">

  <ng-container [ngSwitch]="loadingConfig.type.toString()">
    <ng-container *ngSwitchCase="loadingStandard">
      <div class="cssload-loader">
        <div class="cssload-inner cssload-one"></div>
        <div class="cssload-inner cssload-two"></div>
        <div class="cssload-inner cssload-three"></div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="loadingBars">
      <div class="loader-bars">
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="loadingCircular">
      <div class="loader-circular">
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="loadingDots">
      <div class="loader-dots">
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="loadingCubeFlipping">
      <div class="cube-wrapper">
        <div class="cube-folding">
          <span class="leaf1"></span>
          <span class="leaf2"></span>
          <span class="leaf3"></span>
          <span class="leaf4"></span>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="loadingCircles">
      <div id="preloader">
        <div id="loader"></div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="loadingExplosingSquares">
      <div class="loader">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </ng-container>
    <!--<ng-container *ngSwitchDefault>-->
    <!---->
    <!--</ng-container>-->
  </ng-container>
</div>
