<nav class="dws-menu">
  <input type="checkbox" name="toggle" id="menu" class="toggleMenu" />
  <label for="menu" class="toggleMenu"> </label>

  <div id="mainMenu" class="mainMenu" [ngClass]="sizeMenuClass" [@animationForm]="sizeMenuClass">
    <ng-container *ngFor="let m of getVisibleMenu(menu); let i = index">
      <div [routerLinkActive]="['is-active-expand']">
        <div *ngIf="m.children && m.children.length; else noChildren" class="menu-item-wrapper">
          <div class="menu-item expand-item childrens" (click)="expand(m)">
            <fa-icon class="icon" [icon]="m.icon" title="++{{ m.header | translate }}"></fa-icon>

            <span [@animationForm]="sizeMenuClass === 'small' ? 'tsmall' : 'tbig'" class="title">
              {{ m.header | translate }}
            </span>
            <fa-icon
              *ngIf="m.children"
              [@animationForm]="sizeMenuClass === 'small' ? 'tsmall' : 'tbig'"
              class="icon-expand"
              [icon]="['fas', m.expanded ? 'chevron-up' : 'chevron-down']"
            ></fa-icon>

            <!--Small Sub Menu-->
            <div class="submn">
              <div *ngFor="let subm of getVisibleMenu(m.children); let subi = index">
                <a [routerLink]="subm.url">
                  <fa-icon class="icon" [icon]="['fas', 'circle']"></fa-icon>
                  <span class="title">
                    {{ subm.header | translate }}
                  </span>
                </a>
              </div>
            </div>
          </div>

          <!--Big Sub Menu-->
          <div
            *ngFor="let subm of getVisibleMenu(m.children); let subi = index"
            class="submenu menu-item-wrapper"
            [routerLinkActive]="['is-active']"
            [ngClass]="{ 'is-invisible': sizeMenuClass === 'small' || !m.expanded }"
          >
            <a [routerLink]="subm.url" class="menu-item">
              <fa-icon class="icon" [icon]="['fas', 'circle']"></fa-icon>
              <span [@animationForm]="sizeMenuClass === 'small' ? 'tsmall' : 'tbig'" class="title">
                {{ subm.header | translate }}
              </span>
            </a>
          </div>
        </div>

        <ng-template #noChildren>
          <div [routerLinkActive]="['is-active']" class="menu-item-wrapper">
            <a [routerLink]="m.url" class="menu-item" [routerLinkActive]="['is-active']">
              <fa-icon class="icon" [icon]="m.icon" title="{{ m.header | translate }}"></fa-icon>

              <span [@animationForm]="sizeMenuClass === 'small' ? 'tsmall' : 'tbig'" class="title">
                {{ m.header | translate }}
              </span>
            </a>
          </div>
        </ng-template>
      </div>
    </ng-container>

    <div class="menu-item-wrapper">
      <a [routerLink]="['/']" class="menu-item" (click)="logout()">
        <fa-icon
          class="icon"
          [icon]="['fas', 'door-open']"
          title="{{ 'layouts.navigation.EXIT' | translate }}"
        ></fa-icon>

        <span [@animationForm]="sizeMenuClass === 'small' ? 'tsmall' : 'tbig'" class="title">
          {{ 'layouts.navigation.EXIT' | translate }}
        </span>
      </a>
    </div>
  </div>
</nav>
