import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommentsDataSource } from '../../model/comments-data-source.interface';
import { ButtonType } from '../../model/buttons-config.interface';

@Component({
  selector: 'ks-info-block',
  templateUrl: './info-block.component.html',
  styleUrls: ['./info-block.component.scss']
})
export class InfoBlockComponent implements OnInit, OnDestroy {
  @Input() infoTemplate;
  @Input() infoBlockContent;
  @Input() content: ButtonType; // компонент который будет грузиться внутрь инфо блока
  /**
   * DataSource для работы с комментариями
   */
  @Input()
  commentsDataSource: CommentsDataSource;

  /**
   * Id текущей картинки.
   */
  @Input() imageId: number;

  @Output() resetSizeStyle = new EventEmitter();

  @Output() onClose = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit() {
    this.resetSizeStyle.emit();
  }

  ngOnDestroy() {
    this.onClose.emit(true);
  }

  close() {
    this.onClose.emit(true);
  }
}
