import { ProjectModel } from '@app/core/model/project.model';
import { WorkedDayModel } from '@app/core/model/worked-days.model';

interface IProjectStore {
  projectStoreId: number;
  projectStoreName: string;
  projectStoreCode: string;
  projectStoreChainBanner: string;
  fullAddress: string;
  latitude: number;
  longitude: number;
  project: ProjectModel;
  workedDays: WorkedDayModel[];
  storeStatus: { id: number; name: string };
  routeVisitPeriodRule: { id: number; name: string };
}

export class ProjectStoreModel implements IProjectStore {
  projectStoreId = null;
  projectStoreName = '';
  projectStoreCode = '';
  projectStoreChainBanner = '';
  fullAddress = '';
  latitude = 0;
  longitude = 0;
  project;
  workedDays;
  storeStatus = null;
  routeVisitPeriodRule = null;

  constructor(data = {}) {
    Object.assign(this, data);

    this.workedDays =
      data && data.hasOwnProperty('workedDays')
        ? data['workedDays'].map(e => new WorkedDayModel(e))
        : new Array(7).map(() => {
            return new WorkedDayModel({ status: true, timeBegin: '01:00', timeEnd: '23:00' });
          });
  }
}
