import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { BodyComponent } from '@app/layouts/body/body.component';
import { AuthLayoutComponent } from '@app/layouts/auth-layout/auth-layout.component';
import { NoAuthGuard } from '@app/core/guards/no-auth.guard';
import { AuthGuard } from '@app/core/guards/auth.guard';
import { CONTENT_ROUTES } from '@app/shared/routes/pages.routes';
import { UserResolver } from '@app/core/guards/user.resolver';
import { PermissionsResolver } from '@app/core/guards';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/auth/login',
    pathMatch: 'full',
    data: {
      breadcrumb: 'breadcrumbs.home.HOME'
    }
  },
  {
    path: '',
    component: BodyComponent,
    canActivate: [NoAuthGuard],
    resolve: { user: UserResolver, permissions: PermissionsResolver },
    children: CONTENT_ROUTES
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('@app/modules/auth/auth.module').then(m => m.AuthModule)
  },

  // Fallback when no prior routes is matched
  { path: '**', redirectTo: '/auth/login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {}
