import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { Metrika } from 'ng-yandex-metrika';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { HttpService, UserService } from '@app/core/services';
import { environment } from '@env/environment';
import { ToastrService } from 'ngx-toastr';
import { ReportNotificationService } from './core/services/report-notification.service';
import { TranslateService } from '@ngx-translate/core';
import { PhotoReportNotificationService } from './core/services/photo-report-notification.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public version = environment.version;

  constructor(
    private titleService: Title,
    private metrika: Metrika,
    private router: Router,
    private location: Location,
    private userService: UserService,
    private httpService: HttpService,
    private toastr: ToastrService,
    private reportNotificationService: ReportNotificationService,
    private photoReportNotificationService: PhotoReportNotificationService,
    private translate: TranslateService,
  ) {
    console.log("AppComponent")
    translate.setDefaultLang('ru');
    titleService.setTitle('TR Portal');
    // translate.setDefaultLang('ru');
    let prevPath = this.location.path();
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      const newPath = this.location.path();

      this.metrika.hit(newPath, {
        referer: prevPath,
        params: {
          userId: this.userService.getUser() ? this.userService.getUser()['id'] : null,
          login: this.userService.getUser() ? this.userService.getUser()['login'] : 'anonimus'
        }
      });
      // toastr.success("test <a href='https://www.npmjs.com/package/ngx-toastr'> ссылка <a/>","",{enableHtml:true})
      prevPath = newPath;
    });
    reportNotificationService.initialization()
    photoReportNotificationService.initialization()
  }
}

