<div class="comments-wrapper">
  <div class="header">{{'gallery.comments'  | translate}}</div>
  <div *ngIf="commentsList as comments; else loadingOrError"
       slimScroll
       class="comments">

    <ng-container *ngIf="comments.length > 0; else noContent">
      <ng-container *ngFor="let comment of comments; trackBy: trackByFn">
        <ks-comment
          class="comment-gallery"
          [comment]="comment"
        ></ks-comment>
      </ng-container>
    </ng-container>

    <ng-template #noContent>
      <div class="comments__loading comments__loading__no-content">
        {{'gallery.no_comments'  | translate}}
        
      </div>
    </ng-template>

  </div>
</div>
<ks-sender
  [(formStatus)]="sendFormStatus"
  (onSendMessage)="sendMessage($event)"
></ks-sender>


<ng-template #loadingOrError>
  <div *ngIf="loadingError$ | async as error; else loading" class="comments__loading">
    <span class="color-red">{{error}}</span>
  </div>
  <ng-template #loading>
    <div class="comments__loading">
      <fa-icon class="tr-spin" [icon]="['fas', 'spinner']"></fa-icon>
    </div>
  </ng-template>
</ng-template>
