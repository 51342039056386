export interface ITaskGroupModel {
  id: number;
  taskGroupName: string;
}

export class TaskGroupModel implements ITaskGroupModel {
  public id = null;
  public taskGroupName: string = '';

  constructor(data = {}) {
    Object.assign(this, data);
  }
}


export interface IVisitModel {
  id: number;
  date: string;
  isActive: boolean;
  visitTimeSpent: number;
  userLogin: string;
  userFullName: string;
  projectName: string;
  regionName: string;
  cityName: string;
  fullAddress: string;
  projectStoreCode: string;
  startServerTime: string;

}

export class VisitModel implements IVisitModel {
  public id = null;
  public date: string = '';
  public isActive: boolean = true;
  public visitTimeSpent: number = 0;
  public userLogin: string = '';
  public regionName: string = '';
  public cityName: string = '';
  public userFullName: string = '';
  public projectStoreCode: string = '';
  public fullAddress: string = '';
  public startServerTime: string = '';
  public projectName: string = '';


  constructor(data = {}) {
    Object.assign(this, data);
  }
}

export interface IVisitDetailModel {
  visitTimeSpent: number;
  latitude: number;
  longitude: number;
  projectStoreChainBanner: string;
  taskGroups: ITaskGroupModel[];
}

export class VisitDetailModel extends VisitModel implements IVisitDetailModel {
  public visitTimeSpent: number;
  public latitude: number;
  public longitude: number;
  public projectStoreChainBanner: string;
  public taskGroups: ITaskGroupModel[] = [];

  constructor(data = {}) {
    super();
    Object.assign(this, data);
  }
}
