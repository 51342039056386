import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { PermissionsService } from '@app/core/services';
import { Observable, of } from 'rxjs';

@Injectable()
export class CanActivateTeam  {
  constructor(private ps: PermissionsService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // Если не указаны пермишены для роута, то пускаем всех.
    if (!route.data['permission']) {
      return of(true);
    }

    const isAccept = this.ps.has(route.data['permission']);

    if (!isAccept) {
      this.router.navigate(['/', 'home']);
    }

    return of(isAccept);
  }
}
