<div class="body-wrapper">
  <div class="header">
    <app-header (changeMenuSizeClass)="onChangeMenuSizeClass($event)"></app-header>
  </div>

  <div class="content-wrapper">
    <div class="main-menu short-menu">
      <app-nav [(sizeMenuClass)]="menuSizeClass"></app-nav>
    </div>

    <div class="content" [ngClass]="contentClass">
      <xng-breadcrumb class="cust-brcmbs" separator=" ">
        <ng-container *xngBreadcrumbItem="let breadcrumb">
          <ng-container>{{ breadcrumb | translate }}</ng-container>
        </ng-container>
      </xng-breadcrumb>

      <div class="body">
        <router-outlet #outlet="outlet"></router-outlet>
      </div>
    </div>
  </div>

  <div class="footer">
    {{ 'home.FOOTER' | translate }} <span> v{{ version }} </span>
  </div>
</div>
