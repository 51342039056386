<div class="comment single-comment-gallery">
  <div class="comment__info info info_custom">
    <div class="info__avater">
      <ng-container *ngIf="comment.user?.photoUrl; else noAva">
        <img class="border-radius-50" src="{{comment.user.photoUrl}}">
      </ng-container>
      <ng-template #noAva>
        <fa-icon class="default-avatar" [icon]="['fas', 'user-circle']"></fa-icon>
      </ng-template>

    </div>
    <div class="info__username">{{comment.user?.fullName || comment.user?.login}}</div>
    <div class="info__date info__date_custom">{{comment.commentCreatedDate | date: 'dd.MM.yyyy г. в HH:mm'}}</div>
  </div>

  <div class="comment__text comment__text_custom">
    {{comment.comment}}
  </div>
</div>
