import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TokenInterceptor } from './interceptors';
import { AuthGuard, CanActivateTeam, NoAuthGuard } from '@app/core/guards';

@NgModule({ declarations: [], imports: [], providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        AuthGuard,
        NoAuthGuard,
        CanActivateTeam,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class CoreModule {
  constructor() {}
}
