import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { HttpService, PermissionsService } from '@app/core/services';
import { catchError, tap } from 'rxjs/operators';

@Injectable()
export class PermissionsResolver  {
  constructor(private httpService: HttpService, private permissionsService: PermissionsService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.httpService.get('user-service/api/permissions').pipe(
     
      tap(permissions => {
        this.permissionsService.setPermissions(permissions);
      }),
      catchError(error => {
        console.error(`Resolve: permissions`);
        console.error(error);

        this.permissionsService.setPermissions({});

        return of({});
      })
    );
  }
}
