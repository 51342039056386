<nav *ngIf="configPreview?.visible"
     class="previews-container"
     [attr.aria-label]="accessibilityConfig?.carouselPreviewsContainerAriaLabel"
     [title]="accessibilityConfig?.carouselPreviewsContainerTitle">

  <a class="nav-left"
     [attr.aria-label]="accessibilityConfig?.carouselPreviewScrollPrevAriaLabel"
     [tabindex]="configPreview.arrows && start > 0 ? 0 : -1" role="button"
     (click)="onNavigationEvent('left', $event)" (keyup)="onNavigationEvent('left', $event)">
    <div class="inside {{configPreview.arrows && start > 0 ? 'left-arrow-preview-image' : 'empty-arrow-preview-image'}}"
         aria-hidden="true"
         [title]="accessibilityConfig?.carouselPreviewScrollPrevTitle"></div>
  </a>

  <div class="preview-inner-container">
    <ng-container *ngFor="let preview of previews; trackBy: trackById; let index = index">
      <ng-container *ngIf="!carouselConfig?.legacyIE11Mode; else legacyMode">
        <img *ngIf="preview?.modal?.img"
             class="inside preview-image{{isActive(preview) ? ' active' : ''}}{{!configPreview.clickable ? ' unclickable' : ''}}"
             [src]="preview.plain?.img ? preview.plain.img : preview.modal.img"
             ksSize [sizeConfig]="{width: configPreview?.width,
                                 height: configPreview?.maxHeight}"
             [attr.aria-label]="getAriaLabel(preview)"
             [title]="getTitle(preview)"
             alt="{{getAlt(preview)}}"
             [tabindex]="0" role="img"
             (click)="onImageEvent(preview, $event, clickAction)"
             (keyup)="onImageEvent(preview, $event, keyboardAction)"/>
      </ng-container>
      <ng-template #legacyMode>
        <div *ngIf="preview?.modal?.img"
             class="inside preview-ie11-image{{isActive(preview) ? ' active' : ''}}{{!configPreview.clickable ? ' unclickable' : ''}}"
             [style.background-color]="'transparent'"
             [style.background-image]="sanitizeUrlBgStyle(preview.plain?.img ? preview.plain.img : preview.modal.img)"
             [style.background-position]="'center center'"
             [style.background-size]="getIE11LegacyBgSize()"
             [style.background-repeat]="'no-repeat'"
             [style.background-attachment]="'scroll'"
             ksSize [sizeConfig]="{width: configPreview?.width,
                                 height: configPreview?.maxHeight}"
             [attr.aria-label]="getAriaLabel(preview)"
             [title]="getTitle(preview)"
             [tabindex]="0" role="img"
             (click)="onImageEvent(preview, $event, clickAction)"
             (keyup)="onImageEvent(preview, $event, keyboardAction)"></div>
      </ng-template>
    </ng-container>
  </div>

  <a class="nav-right"
     [attr.aria-label]="accessibilityConfig?.carouselPreviewScrollNextAriaLabel"
     [tabindex]="configPreview.arrows && end < images.length ? 0 : -1" role="button"
     (click)="onNavigationEvent('right', $event)" (keyup)="onNavigationEvent('right', $event)">
    <div
      class="inside {{configPreview.arrows && end < images.length ? 'right-arrow-preview-image' : 'empty-arrow-preview-image'}}"
      aria-hidden="true"
      [title]="accessibilityConfig?.carouselPreviewScrollNextTitle"></div>
  </a>

</nav>
