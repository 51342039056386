import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { HttpService, UserService } from '@app/core/services';
import { JwtHelperService } from '@auth0/angular-jwt';
import { catchError, tap } from 'rxjs/operators';
import { UserModel } from '@app/core/model';
import { locale } from 'devextreme/localization';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'ngx-webstorage';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class UserResolver  {
  constructor(
    private httpService: HttpService,
    private jwt: JwtHelperService,
    private userService: UserService,
    private translate: TranslateService,
    private toastrService: ToastrService

  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UserModel> {
    return this.httpService.get('user-service/api/users/me').pipe(
      tap((user: UserModel) => {
        this.userService.setUser(user);

        // Определяем язык пользователя и устанавливаем его для сайта и компонентов.
        const lang = user.languageKey || 'ru';
        this.translate.use(lang);
        locale(lang);
      }),
      catchError(error => {
        if(error.status=403) {
          this.toastrService.error("Ошибка: 403", "Ошибка");
          this.userService.logout();
        }
        let user = new UserModel();
        this.userService.setUser(user);
        return of(user);
      })
    );
  }


}
