import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export class CommentSendStatus {
  public error: string;
  public loading: boolean;
}

@Component({
  selector: 'ks-sender',
  templateUrl: './sender.component.html',
  styleUrls: ['./sender.component.scss']
})
export class SenderComponent implements OnInit, OnDestroy {
  /**
   * Привязка к textarea.
   */
  @ViewChild('message') message: ElementRef;

  /**
   * Двухсторонний биндинг статуса формы.
   */
  @Input('formStatus') formStatus$: BehaviorSubject<CommentSendStatus>;
  @Output('formStatusChange') formStatusChange = new EventEmitter<BehaviorSubject<CommentSendStatus>>();

  /**
   * Событие отправки введенного сообщения.
   */
  @Output() onSendMessage = new EventEmitter<string>();

  /**
   * Subject для отписки.
   */
  private ngUnsubscribe = new Subject();

  isEmpty: boolean = false;
  placeholder: string = 'Введите комментарий...';

  constructor() {
  }

  ngOnInit() {
    // Задаем стартовые значения состояния формы.
    this.formStatus$ = new BehaviorSubject({error: '', loading: false});
    this.formStatusChange.emit(this.formStatus$);

    // Подписываемся на состояние формы для отслеживания успешной отправки комментария и обнуления textarea
    this.formStatus$
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(v => {
        if (!this.message) return;

        if (v.error === '' && v.loading === false) {
          this.message.nativeElement.value = '';
        }
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /**
   * Отправка сообщения
   * @param textarea
   */
  send(textarea: HTMLTextAreaElement) {
    if (!textarea.value) {
      //TODO Проверка на пустую форму и подсветка

      this.alertEmptyMessage();

      return;
    }

    this.onSendMessage.emit(textarea.value);
  }

  /**
   * Скрыть блок с ошибкой.
   */
  alertEmptyMessage() {
    this.isEmpty = true;
    this.placeholder = 'Пустой комментарий';
  }

  // Скрыть блок с ошибкой.
  closeErrorBlock() {
    this.formStatus$.next({error: '', loading: false});
  }

  resetAlert() {
    this.isEmpty = false;
    this.placeholder = 'Введите комментарий...';
  }
}
