<header class="buttons-container">

  <ng-container *ngIf="!configButtons || configButtons?.visible">
    <ng-container *ngFor="let btn of buttons; trackBy: trackById; let index = index">
      <a *ngIf="btn.visible"
         class="upper-button"
         ksSize [sizeConfig]="{width: btn.size?.width, height: btn.size?.height}"
         [ngStyle]="{'font-size': btn.fontSize}"
         [attr.aria-label]="btn.ariaLabel"
         [tabindex]="0" role="button"
         (click)="onEvent(btn, $event)"
         (keyup)="onEvent(btn, $event)"
      >
        <fa-icon class="inside" [icon]="btn.className.split(' ')" [title]="btn.title"></fa-icon>
      </a>
    </ng-container>
  </ng-container>
</header>
