import { NgModule } from '@angular/core';
import { YamapngComponent } from './yamapng.component';

@NgModule({
  declarations: [YamapngComponent],
  imports: [],
  exports: [YamapngComponent]
})
export class YamapngModule {
}
