import { Component, Input, OnInit } from '@angular/core';

export interface IComment {
  comment: string;
  commentCreatedDate: number;
  photoCode: string;
  user: {
    fullName: string;
    id: number;
    login: string;
    photoUrl: string;
  }
}

@Component({
  selector: 'ks-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit {
  @Input()
  public comment: IComment;

  constructor() {
  }

  ngOnInit() {
  }

}
