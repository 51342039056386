import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  constructor() {}

  public permissionsState: { [key: string]: boolean };

  getPermissions() {
    return this.permissionsState;
  }

  setPermissions(value) {
    this.permissionsState = value;
  }

  has(key: string): boolean {
    if (!this.permissionsState.hasOwnProperty(key)) {
      return false;
    }

    return this.permissionsState[key];
  }
}
