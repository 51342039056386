<div class="f-home__header">
  <div class="hamburger" [ngClass]="menuSizeClass" (click)="onChangeMenuSizeClass(menuSizeClass)">
    <fa-icon [icon]="['fas', 'bars']"></fa-icon>
  </div>

  <div class="f-home__logo">
    <div class="f-home__logo-wrapper">
      <a [routerLink]="['/']">
        <img class="img__f-home__logo" src="/assets/images/svg/logo.svg" alt="TRportal" />
      </a>
    </div>
  </div>

  <div class="f-home__header-user">
    <div class="user-info">
      <div class="user-info-data">
        <a [routerLink]="['/','settings', 'user']" class="settings-user">
          <span class="login">{{ user.login }}</span>
        </a>
      </div>
      <div class="avatar">
        <a [routerLink]="['/','settings', 'user']">
          <ng-container *ngIf="user.photoUrlSmallSize; else noAva">
            <img class="avatar-icon avatar-icon__border"  src="{{ user.photoUrlSmallSize }}" />
          </ng-container>
          <ng-template #noAva>
            <fa-icon class="no-avatar" [icon]="['fas', 'user-circle']"></fa-icon>
          </ng-template>
        </a>
        </div>
    </div>
  </div>
</div>
