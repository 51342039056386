import { RouteTypeModel } from '@app/core/model/route-type.model';

export interface IProjectModel {
  id: number;
  name: string;
  code: string;
  description: string;
  abbreviation: string;
  colorText: string;
  colorBackground: string;
  isActive: boolean;
  useLoadingDataByServer: boolean;
  url: string;
  siteUrl: string;
  userWageRate: number;
  wageRate: number;
  wageRateStep: number;
  routeType: RouteTypeModel | null;
}

export class ProjectModel implements IProjectModel {
  public id = null;
  public name;
  public code;
  public description;
  public abbreviation = '';
  public colorText = '';
  public colorBackground = '';
  public isActive = true;
  public useLoadingDataByServer = false;
  public url;
  public siteUrl;
  public userWageRate = 0;
  public wageRate = 0;;
  public wageRateStep = 0;
  public routeType = null;

  constructor(data = {}) {
    Object.assign(this, data);
  }
}
