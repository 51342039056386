import { ProjectModel } from '@app/core/model/project.model';
import { WorkedDayModel } from '@app/core/model/worked-days.model';
import { PositionModel } from '@app/core/model/position.model';

export class UserModel {
  id = null;
  active = true;
  login: string = '';
  name: string = '';
  fullName: string = '';
  firstName: string = '';
  lastName: string = '';
  middleName: string = '';
  password: string = '';
  confirmPassword?: string = '';
  clusterId?: number[];
  phoneNumber: string = '';
  position: PositionModel = null;
  email: string = '';
  languageKey: string = 'ru';
  roleCode: string;
  roleName: string;
  movingType: { id: number; type: string } = { id: null, type: '' };
  movingTypeId: number = null;
  positionId: number;
  positionName: string;
  hasExtendedPermission = false;
  imagePosition: { x1: number; x2: number; y1: number; y2: number };
  photoUrlSmallSize?: string ;
  photoUrlMiddleSize?: string ;
  photoUrlOriginalSize?: string ;
  dataSourceSwitchEnable = false;
  projects: ProjectModel[] = [];
  supervisors = [];
  storeCount = 0;
  workedDays: WorkedDayModel[] = [];
  address: string = '';
  hasCluster: boolean = null;
  hasRoutePlan: boolean = null;
  isVisitTimeAvailable: boolean = false;
  isFteAvailable: boolean = false;
  absentUser: {dateBeg?: string, dateEnd?: string, isActive: boolean, userId:number, id:number}
  ProjectBranch?: Array<{id:number, name:string}> 
  ProjectStoreChain?: Array<{id:number, name:string}> 
  constructor(data = {}) {
    Object.assign(this, data);
    if(this.absentUser!=null){
      this.absentUser.userId = this.id;
      this.absentUser.id = this.id;
    }
    else{
      this.absentUser = {
        userId : this.id,
        id : this.id,
        isActive: false
      };
    }
  }
}
