import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UserModel } from '@app/core/model';
import { UserService } from '@app/core/services';
import { TranslateService } from '@ngx-translate/core';
import { pipe, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Output() changeMenuSizeClass = new EventEmitter();

  public user: UserModel;

  public menuSizeClass = localStorage.getItem('menuSizeClass') || 'big';

  constructor(private userService: UserService, private translate: TranslateService) {
    this.userService.getUserStream().subscribe(data => (this.user = data));
  }

  ngOnInit() {}

  onChangeMenuSizeClass(menuSizeClass: string): void {
    this.menuSizeClass = menuSizeClass === 'small' ? 'big' : 'small';
    localStorage.setItem('menuSizeClass', this.menuSizeClass);
    this.changeMenuSizeClass.emit(this.menuSizeClass);
  }
}
