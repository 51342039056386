interface IWorkedDay {
  status: boolean;
  timeBegin: string;
  timeEnd: string;
}

export class WorkedDayModel implements IWorkedDay {
  status = true;
  timeBegin = '01:00';
  timeEnd = '23:00';

  constructor(data = {}) {
    Object.assign(this, data);
  }
}
