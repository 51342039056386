<div class="comments-wrapper">
  <div class="header">  {{'gallery.information'  | translate}}</div>
  <div slimScroll
       class="comments"
  >

    <div
      *ngIf="infoTemplate; else noContent"
      [innerHTML]="template"
      class="padding-5"
    >
    </div>

    <ng-template #noContent>
      <div class="comments__loading comments__loading__no-content">
        {{'gallery.no_photo_data'  | translate}}
      </div>
    </ng-template>

  </div>
</div>


