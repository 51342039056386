export class RoutePlan {
  active: boolean;
  check: boolean;
  dateInt: number;
  dateIntDate: Date;
  id: number;
  numberOrderOfVisits: number;
  storeChainBanner: string;
  storeChainName: string;
  storeCode: string;
  projectStoreCode: string;
  storeFullAddress: string;
  storeId: number;
  projectId: number;
  projectName: string;
  projectStoreId: number;
  storeLatitude: number;
  storeLongitude: number;
  userFullName: string;
  userId: number;
  userLogin: string;

  constructor(data?: any) {
    this.active = data && data.hasOwnProperty('active') ? data.active : true;
    this.check = data && data.hasOwnProperty('check') ? data.check : false;
    this.dateInt = data && data.hasOwnProperty('dateInt') ? data.dateInt : +new Date().convertDateToServerString();
    this.dateIntDate = this.dateInt.toString().convertServerStringToDate() || new Date();
    this.id = data && data.hasOwnProperty('id') ? data.id : null;
    this.numberOrderOfVisits = data && data.hasOwnProperty('numberOrderOfVisits') ? data.numberOrderOfVisits : 1;
    this.storeChainBanner = data && data.hasOwnProperty('storeChainBanner') ? data.storeChainBanner : '';
    this.storeChainName = data && data.hasOwnProperty('storeChainName') ? data.storeChainName : '';
    this.storeCode = data && data.hasOwnProperty('storeCode') ? data.storeCode : '';
    this.projectStoreCode = data && data.hasOwnProperty('projectStoreCode') ? data.projectStoreCode : '';
    this.storeFullAddress = data && data.hasOwnProperty('storeFullAddress') ? data.storeFullAddress : '';
    this.storeId = data && data.hasOwnProperty('storeId') ? data.storeId : null;
    this.projectId = data && data.hasOwnProperty('projectId') ? data.projectId : null;
    this.projectName = data && data.hasOwnProperty('projectName') ? data.projectName : '';
    this.projectStoreId = data && data.hasOwnProperty('projectStoreId') ? data.projectStoreId : null;
    this.storeLatitude = data && data.hasOwnProperty('storeLatitude') ? data.storeLatitude : '';
    this.storeLongitude = data && data.hasOwnProperty('storeLongitude') ? data.storeLongitude : '';
    this.userFullName = data && data.hasOwnProperty('userFullName') ? data.userFullName : '';
    this.userId = data && data.hasOwnProperty('userId') ? data.userId : null;
    this.userLogin = data && data.hasOwnProperty('userLogin') ? data.userLogin : '';
  }
}
