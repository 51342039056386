import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LocalStorageService } from 'ngx-webstorage';
import { UserService } from '../services';

@Injectable()
export class NoAuthGuard  {
  constructor(
    private jwt: JwtHelperService, 
    private router: Router,
    private storage:LocalStorageService,
    private userService: UserService,) {}

  canActivate(): boolean {
    //  const token = localStorage.getItem('ngx-webstorage|access_token');
    let token = this.storage.retrieve('access_token');
    // Проверяем наличие токена
    if (!token) {
      // alert("noToken NoAuthGuard")
      this.userService.logout()
      return false;
    }

    // Проверяем валидность токена
    if (this.jwt.isTokenExpired(token)) {
      //  alert("isTokenExpired NoAuthGuard")
      this.userService.logout()
      return false;
    }

    return true;
  }
}
