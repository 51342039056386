import { ProjectModel } from '@app/core/model/project.model';

export interface IStoreModel {
  storeId: number;
  storeName: string;
  storeCode: string;
  projectStoreName: string;
  projectStoreCode: string;
  projectStoreChainBanner: string;
  fullAddress: string;
  latitude: number;
  longitude: number;
  mchCount: number;
  projects: ProjectModel[];
}

export class StoreModel implements IStoreModel {
  storeId = null;
  storeName = '';
  storeCode = '';
  projectStoreName = '';
  projectStoreCode = '';
  projectStoreChainBanner = '';
  fullAddress = '';
  latitude = 0;
  longitude = 0;
  mchCount = 0;
  projects = [];

  constructor(data = {}) {
    Object.assign(this, data);

    this.projects = data && data.hasOwnProperty('projects') ? (data['projects'] ? data['projects'] : []) : [];
  }
}
