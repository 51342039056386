<nav class="dots-container" [attr.aria-label]="accessibilityConfig?.dotsContainerAriaLabel"
     [title]="accessibilityConfig?.dotsContainerTitle">
  <ng-container *ngIf="!configDots || configDots?.visible">
    <div class="inside dot"
         *ngFor="let img of images; trackBy: trackById; let index = index"
         [ngClass]="{'active': isActive(index)}"
         [attr.aria-label]="accessibilityConfig?.dotAriaLabel + ' ' + (index + 1)"
         [tabindex]="0" role="navigation"
         (click)="onDotEvent(index, $event)" (keyup)="onDotEvent(index, $event)"></div>
  </ng-container>
</nav>
