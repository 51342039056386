import { Component, OnInit, ViewChild,ChangeDetectorRef } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { slideInAnimation } from '@app/core/const';
import { HttpService, ProjectService, UserService } from '@app/core/services';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@env/environment';

import {BreadcrumbModule } from 'params-xng-breadcrumb';

// import * as ruMessages from 'devextreme/localization/messages/ru.json';

@Component({
  selector: 'app-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.scss'],
  animations: [slideInAnimation]
})
export class BodyComponent implements OnInit {
  public version = environment.version;
  public menuSizeClass = localStorage.getItem('menuSizeClass') || 'big';

  // rus = ruMessages;

  constructor(
    private httpService: HttpService,
    private projectService: ProjectService,
    private userService: UserService,
    private translate: TranslateService,
    private ref: ChangeDetectorRef,
    // private breadcrumbService :BreadcrumbService
    
  ) {
    translate.setDefaultLang('ru');
    // loadMessages(this.rus);
    // locale('ru');
  }

  ngOnInit() {}

  onChangeMenuSizeClass(e: string) {
    this.menuSizeClass = e;
    this.ref.detectChanges();
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  
  get contentClass () {
    let mainMenu= document.getElementById("mainMenu")
    return {"big":mainMenu.classList.contains("big"), "small":mainMenu.classList.contains("small")}
  }
}
