import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ProjectModel } from '@app/core/model';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  private project: BehaviorSubject<ProjectModel>;

  constructor() {
    this.project = new BehaviorSubject<ProjectModel>(null);
  }

  clearProject() {
    this.project.next(null);
  }

  setProject(message) {
    this.project.next(message);
    localStorage.setItem('projectName', message.siteUrl);
  }

  getProject(): ProjectModel {
    return this.project.getValue();
  }
}
