import { Component, Input, OnInit, SimpleChange } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'ks-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {
  @Input() infoTemplate;
  template;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnChanges(changes) {
    this.template = this.sanitizer.bypassSecurityTrustHtml(
      this.replace(changes.infoTemplate.currentValue.template, changes.infoTemplate.currentValue.photoInfo)
    );
  }

  ngOnInit(): void {}

  replace(template, photoInfo) {
    template = template.split('\\n');
    template = template.map(elem => {
      if (elem) {
        return `<li>${elem}</li>`;
      }
    });
    template = template.join('');
    template = `<ul class="list">${template}</ul>`;
    let text = template;

    for (let key in photoInfo) {
      if (key === 'VisitDateInt') {
        const date = photoInfo[key];
        const formatter = new Intl.DateTimeFormat('ru', {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        });
        const dateStringRU = formatter.format(date);
        text = text.replace('{{' + key + '}}', dateStringRU);
        continue;
      }
      text = text.replace('{{' + key + '}}', photoInfo[key]);
    }

    return text;
  }
}
