import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as env } from '@env/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

const BASE_URL = env.serverUrl;

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private headers = new HttpHeaders();

  constructor(private httpClient: HttpClient) {}

  setUrl(path:string){
 return BASE_URL + path
  }

  public get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.httpClient.get(BASE_URL + path, { params }).pipe(catchError(this.formatErrors));
  }

  public getResponse(path: string, params: HttpParams = new HttpParams()): Observable<HttpResponse<object>> {
    return this.httpClient.get(BASE_URL + path, { params, observe: 'response' }).pipe(catchError(this.formatErrors));
  }

  public getBlobGet(path: string, params: HttpParams = new HttpParams()): Observable<HttpResponse<object>> {
    return this.httpClient.get(BASE_URL + path, {
      headers: this.headers.set('Content-Type', 'application/octet-stream'),
      params,
      responseType: 'arraybuffer',
      observe: 'response'
    });
  }

  public getBlobGetTest(path: string, params: HttpParams = new HttpParams()): any {
    return this.httpClient.get(BASE_URL + path, {
      headers: this.headers.set('Content-Type', 'application/octet-stream'),
      params,
      responseType: 'blob',
      observe: 'response'
    });
  }
  

  public getBlob(
    path: string,
    body: object = {},
    params: HttpParams = new HttpParams()
  ): Observable<HttpResponse<object>> {
    return this.httpClient
      .post(BASE_URL + path, JSON.stringify(body), {
        headers: this.headers.set('Content-Type', 'application/json'),
        params,
        responseType: 'blob',
        observe: 'response'
      })
      .pipe(catchError(this.formatErrors));
  }
  public getBlobTest(
    path: string,
    body: object = {},
    params: HttpParams = new HttpParams()
  ): Observable<HttpResponse<object>> {
    return this.httpClient
      .post( path, JSON.stringify(body), {
        headers: this.headers.set('Content-Type', 'application/json'),
        params,
        responseType: 'blob',
        observe: 'response'
      })
      .pipe(catchError(this.formatErrors));
  }

  public getFileBlob(path: string, params: HttpParams = new HttpParams()): Observable<HttpResponse<object>> {
    return this.httpClient
      .get(BASE_URL + path, {
        params,
        responseType: 'blob',
        observe: 'response'
      })
      .pipe(catchError(this.formatErrors));
  }

  public deleteResponse(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.httpClient
      .delete(BASE_URL + path, {
        params,
        headers: this.headers.set('Content-Type', 'application/json'),
        observe: 'response'
      })
      .pipe(catchError(this.formatErrors));
  }

  public post(path: string, body: object = {}, params = new HttpParams()): Observable<any> {
    return this.httpClient
      .post(BASE_URL + path, JSON.stringify(body), {
        params,
        headers: this.headers.set('Content-Type', 'application/json')
      })
      .pipe(catchError(this.formatErrors));
  }

  public postResponse(path: string, body: object = {}, params = new HttpParams()): Observable<any> {
    return this.httpClient
      .post(BASE_URL + path, JSON.stringify(body), {
        params,
        headers: this.headers.set('Content-Type', 'application/json'),
        observe: 'response'
      })
      .pipe(catchError(this.formatErrors));
  }
  public putResponse(path: string, body: object = {}, params = new HttpParams()): Observable<any> {
    return this.httpClient
      .put(BASE_URL + path, JSON.stringify(body), {
        params,
        headers: this.headers.set('Content-Type', 'application/json'),
        observe: 'response'
      })
      .pipe(catchError(this.formatErrors));
  }

  public postResponseBlob(path: string, body: object = {}, params = new HttpParams()): Observable<any>{
    return this.httpClient
      .post(BASE_URL + path, JSON.stringify(body), {
        params,
        responseType: 'arraybuffer',
        headers: this.headers.set('Content-Type', 'application/json'),
        observe: 'response'
      })
      .pipe(catchError(this.formatErrors))
  }

  public delete(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.httpClient.delete(BASE_URL + path, { params }).pipe(catchError(this.formatErrors));
  }

  public formatErrors(error: any): Observable<any> {
    return throwError(error);
  }

  public postResponseMultipart(path: string, body: object = {}, params = new HttpParams()): Observable<any> {
    const localBody = body instanceof FormData ? body : JSON.stringify(body);
    return this.httpClient
      .post(BASE_URL + path, localBody, { params, headers: this.headers, observe: 'response' })
      .pipe(catchError(this.formatErrors));
  }

  public getTest(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.httpClient.get(path, { params }).pipe(catchError(this.formatErrors));
  }

  public getResponseTest(path: string, params: HttpParams = new HttpParams()): Observable<HttpResponse<object>> {
    return this.httpClient.get(path, { params, observe: 'response', }).pipe(catchError(this.formatErrors));
  }

  public postResponseMultipartTest(path: string, body: object = {}, params = new HttpParams()): Observable<any> {
    const localBody = body instanceof FormData ? body : JSON.stringify(body);
    return this.httpClient
      .post(path, localBody, { params, headers: this.headers, observe: 'response' })
      .pipe(catchError(this.formatErrors));
  }

  public postTest(path: string, body: object = {}, params = new HttpParams()): Observable<any> {
    return this.httpClient
      .post(path, JSON.stringify(body), {
        params,
        headers: this.headers.set('Content-Type', 'application/json')
      })
      .pipe(catchError(this.formatErrors));
  }

  public postResponseTest(path: string, body: object = {}, params = new HttpParams()): Observable<any> {
    return this.httpClient
      .post(path, JSON.stringify(body), {
        params,
        headers: this.headers.set('Content-Type', 'application/json'),
        observe: 'response'
      })
      .pipe(catchError(this.formatErrors));
  }

  public deleteTest(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.httpClient.delete(path, { params }).pipe(catchError(this.formatErrors));
  }

  public deleteResponseTest(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.httpClient
      .delete(path, { params, headers: this.headers.set('Content-Type', 'application/json'), observe: 'response' })
      .pipe(catchError(this.formatErrors));
  }

  public patch(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.httpClient.patch(BASE_URL + path, {},{ params }).pipe(catchError(this.formatErrors));
  }

  public patchResponse(path: string, params: HttpParams = new HttpParams()): Observable<HttpResponse<object>> {
    return this.httpClient.patch(BASE_URL + path, {},{ params, observe: 'response' }).pipe(catchError(this.formatErrors));

  }

}
