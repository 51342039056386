<!-- <ks-comments
  [id]="imageId"
  [commentsDataSource]="commentsDataSource"
></ks-comments> -->

<ng-container [ngSwitch]="+infoBlockContent">
  <ks-comments
    *ngSwitchCase="9"
    [id]="imageId"
    [commentsDataSource]="commentsDataSource"
  >
  </ks-comments>
  <ks-info
    *ngSwitchCase="10"
    [infoTemplate]="infoTemplate"
  >
  </ks-info>
  <p *ngSwitchDefault> No Content </p>
</ng-container>

<span
  class="close-button"
  (click)="close()">
  <fa-icon [icon]="['fas', 'times']"></fa-icon>
</span>
