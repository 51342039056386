import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { UserModel } from '@app/core/model';
import { LocalStorageService } from 'ngx-webstorage';
import { Router } from '@angular/router';
import { ReportNotificationService } from './report-notification.service';

export const MAXAGE =16;

@Injectable({
  providedIn: 'root'
})
export class UserService {
  user: BehaviorSubject<UserModel>;
  

  constructor(    private storage: LocalStorageService, private reportNotificationService: ReportNotificationService,
    private router: Router) {
    this.user = new BehaviorSubject<UserModel>(null);
  }

  setUser(message) {
    this.user.next(message);
  }

  getUser(): UserModel {
    return this.user.getValue();
  }

  getUserObservable(): Observable<UserModel> {
    return of(this.getUser());
  }

  getUserStream() {
    return this.user;
  }

  logout() {
    // alert("logout")
    this.reportNotificationService.stopListerningAfterLogout()
    this.setUser({})
    this.storage.clear('access_token');
    this.router.navigate(['/auth/login']);
  }
}
