import { Injectable } from '@angular/core';
import { ReportNotificationService, ReportsOrder } from './report-notification.service';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from './http.service';
import { take, takeUntil } from 'rxjs/operators';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PhotoReportNotificationService extends ReportNotificationService {
  public reports$: Subject<ReportsOrder[]> = new Subject()

  public reportsResponse$: Subject<HttpResponse<ReportsOrder[]>> = new Subject()
 
  public reportsInOrder$: Subject<ReportsOrder[]> = new Subject()

  public reportsReady$: Subject<ReportsOrder[]> = new Subject()

  showedOrdersIds=[];

  isListerningNotification: boolean;

  isListerningSubject :Subject<boolean> = new Subject();

  countReqests=-1;
  notifier:Subject<any> =new Subject();
  timeoutId;

  constructor(   
    protected toastr: ToastrService,
    protected httpService: HttpService)
    {
        super (toastr,httpService);

        this.reportsInOrder$.subscribe((data)=>{
          this.toastr.info(`Фотоотчет поставлен в очередь (всего ${data.length} фотоотчетов в очереди). Посмотреть список очереди можно по <a href='reports/order' target='_blank' > ссылке </a>  или дождитесь уведомления`,"",{enableHtml:true,timeOut:10000})
        })

        this.reportsReady$.subscribe((data)=>{
          data.forEach((report,i)=>{
            let id = 'link' + i;
            this.markAsWiewed(report)
            let toastr =this.toastr.info(`Отчет <b>"${report.reportName}"</b> готов. Нажмите на кнопку чтобы <a href='${report.url}' download class='${id}'  > скачать</a>`,"",{enableHtml:true,timeOut:0,closeButton:true});
            toastr.onTap .pipe(take(1)) .subscribe((data:any)=>{
              // console.log((event.target as HTMLElement).tagName=="A")
              if((event.target as HTMLElement).tagName=="A") {
                console.log (report)
                this.markDownloaded(report)
              }
   
            })
            // this.onReadyReport();
            this.addReportAsShowed(report)
          })
        })
        onstorage = this.onStorage.bind(this)
        this.fetchIsListerningNotification();
  
  
  
        this.notifier.subscribe((data)=>{
          this.getReportNotify().pipe(
            take(1),//отменит подписку,  когда getReportNotify передаст первое значение.
            takeUntil(this.isListerningSubject),//отменит подписку до первого значения, если прекращение прослушки пришло с другой страницы
          ).subscribe((reportOrder:ReportsOrder[])=>{
            if(reportOrder.length){
              let reportsReady = this.filterNotification(reportOrder)
              if(reportsReady.length) this.reportsReady$.next(reportsReady )
              if(this.hasReportsProcessing(reportOrder)) {
                this.countReqests++;
                this.timeoutId =setTimeout(()=>{
                  this.notifier.next(true)
                },this.getDelay())
              }
              else this.stopListerning()
            }
            else  
              this.stopListerning()
          })
        })
   }

   onReqestOrder(){
    if(!this.getIsListerning()) this.startListerning()
  }
  markAsWiewed(data){
    let params = new HttpParams().append('sessionId',data.sessionId)
    this.httpService.post('photo-service/api/photo/notify/read',{}, params).subscribe((data:any)=>{
    // this.loadreports()
    })
  }  
  markDownloaded(data){
    let params = new HttpParams().append('sessionId',data.sessionId)
    this.httpService.post('photo-service/api/photo/download-report',{}, params).subscribe((data:any)=>{
    this.loadreports()
  })
  }

  onStorage(){
    // console.log("onStorage")
    let isListerning =  !! JSON.parse(localStorage.getItem("isListerningPhotoNotification"))
    // console.log("onStorage",isListerning ,this.isListerningNotification)
    if(isListerning && ! this.isListerningNotification) {
      this.startListerning();
      return;
    }
    if( !isListerning && ! this.isListerningNotification) {
      this.stopListerning();
      return;
    }
    if(!isListerning && this.isListerningNotification){
      this.getReportOrder().subscribe((reportOrder:ReportsOrder[])=>{
        this.reports$.next(reportOrder)
        let reportOrderFiltered = reportOrder.filter((item)=>{return item.statusId==1 ||  item.statusId==2})
        if(!reportOrderFiltered.length) this.stopListerning()
      })
    }
  }

  fetchIsListerningNotification(){
    this.isListerningNotification = !! JSON.parse(localStorage.getItem("isListerningPhotoNotification"))
    if(!this.getIsListerning()) this.isListerningSubject.next(false)
  }

  setIsListerningNotification(boolean: boolean){
    localStorage.setItem("isListerningPhotoNotification",JSON.stringify(boolean))
  }


  getReportOrder(){
    return  this.get('photo-service/api/photo/all')
  }
  getReportOrderResponse(){
    return  this.getResponse('photo-service/api/photo/all')
  }

  getReportNotify(){
    // if()
    return  this.httpService.get('photo-service/api/photo/notify/show')
  }

}
