<main class="main-image-container"
      ksKeyboardNavigation [isOpen]="isOpen" (keyPress)="onKeyPress($event)"
      [attr.aria-label]="accessibilityConfig.mainContainerAriaLabel"
      [title]="accessibilityConfig.mainContainerTitle">

  <div class="left-sub-container">
    <a class="nav-left {{isFirstImage ? 'no-pointer' : ''}}"
       [attr.aria-label]="accessibilityConfig.mainPrevImageAriaLabel"
       [tabindex]="isFirstImage ? -1 : 0" role="button"
       (click)="onNavigationEvent('left', $event, normalAction, isFirstImage)"
       (keyup)="onNavigationEvent('left', $event, normalAction, isFirstImage)">
      <div class="inside {{isFirstImage ? 'empty-arrow-image' : 'left-arrow-image'}}"
           aria-hidden="true"
           [title]="isFirstImage ? '' : accessibilityConfig.mainPrevImageTitle"></div>
    </a>

    <ng-container *ngIf="configSlide?.sidePreviews?.show">
      <ng-container *ngIf="getLeftPreviewImage() as leftPreview">
        <img *ngIf="!isFirstImage; else firstImage"
             class="inside current-image-previous"
             [src]="leftPreview.plain?.img ? leftPreview.plain.img : leftPreview.modal.img"
             [hidden]="loading"
             ksSize
             [sizeConfig]="{width: configSlide.sidePreviews?.size.width, height: configSlide.sidePreviews?.size.height}"
             [attr.aria-label]="leftPreview.modal.ariaLabel"
             [title]="leftPreview.modal.title ? leftPreview.modal.title : getDescriptionToDisplay(leftPreview)"
             alt="{{leftPreview.modal.alt ? leftPreview.modal.alt : getAltDescriptionByImage(leftPreview)}}"
             [tabindex]="0" role="img"
             (click)="onNavigationEvent('left', $event, clickAction)"
             (keyup)="onNavigationEvent('left', $event, keyboardAction)"/>
        <ng-template #firstImage>
          <div class="current-image-previous hidden"
               ksSize
               [sizeConfig]="{width: configSlide.sidePreviews?.size.width, height: configSlide.sidePreviews?.size.height}"></div>
        </ng-template>
      </ng-container>
    </ng-container>
  </div>

  <div class="content" [ngClass]="getRotateClass(currentImage)" (wheel)="onWheel($event)">
    <figure #galleryImageContainer id="current-figure"  (mouseout)="onMouseOut($event)" class="image-container" [style.display]="loading ? 'none' : ''">
      <!-- <div class="imgWraper" > -->
        <img id="current-image" #galleryImage
             class="inside current-image"
             [src]="currentImage.modal.extUrl"
             [attr.aria-label]="currentImage.modal.ariaLabel"
             alt="{{currentImage.modal.alt ? currentImage.modal.alt : getAltDescriptionByImage()}}"
             [tabindex]="0" role="img"
             (load)="onImageLoad($event, galleryImage)"
             (click)="onImageEvent($event, clickAction)"
             (keyup)="onImageEvent($event, keyboardAction)"
             (swipeleft)="swipe($event.type)"
             (swiperight)="swipe($event.type)"
             (loader)="loading = $event"
             
             (mousemove)="onMousemove($event)"
        />
      <!-- </div> -->
      <figcaption *ngIf="getDescriptionToDisplay() !== ''"
                  class="inside description"
                  ksDescription [description]="configCurrentImage?.description"
                  [innerHTML]="getDescriptionToDisplay()">
      </figcaption>
    </figure>

    <input class="check-image" *ngIf="!loading && isCheckable"
           type="checkbox"
           (change)="checkboxChange($event.target.checked)"
           [checked]="isCheck"
    >

    <div class="comments" *ngIf="isOpenInfoBlock"
         [style.display]="loading ? 'none' : ''">
      <ks-info-block
        [imageId]="currentImage.id"
        [commentsDataSource]="commentsDataSource"
        (resetSizeStyle)="onResetSizeStyle()"
        (onClose)="closeInfoBlock($event)"
        [infoTemplate]="infoTemplate"
        [infoBlockContent]="infoBlockContent"
      ></ks-info-block>
    </div>
  </div>

  <div class="right-sub-container">
    <ng-container *ngIf="slideConfig?.sidePreviews?.show">
      <ng-container *ngIf="getRightPreviewImage() as rightPreview">
        <img *ngIf="!isLastImage; else lastImage"
             class="inside current-image-next"
             [src]="rightPreview.plain?.img ? rightPreview.plain.img : rightPreview.modal.img"
             [hidden]="loading"
             ksSize
             [sizeConfig]="{width: slideConfig.sidePreviews?.size.width, height: slideConfig.sidePreviews?.size.height}"
             [attr.aria-label]="rightPreview.modal.ariaLabel"
             [title]="rightPreview.modal.title ? rightPreview.modal.title : getDescriptionToDisplay(rightPreview)"
             alt="{{rightPreview.modal.alt ? rightPreview.modal.alt : getAltDescriptionByImage(rightPreview)}}"
             [tabindex]="0" role="img"
             (click)="onNavigationEvent('right', $event, clickAction)"
             (keyup)="onNavigationEvent('right', $event, keyboardAction)"/>
        <ng-template #lastImage>
          <div class="current-image-next hidden"
               ksSize
               [sizeConfig]="{width: slideConfig.sidePreviews?.size.width, height: slideConfig.sidePreviews?.size.height}">
          </div>
        </ng-template>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="loading && configCurrentImage?.loadingConfig?.enable">
      <ks-loading-spinner [loadingConfig]="configCurrentImage?.loadingConfig"
                          [accessibilityConfig]="accessibilityConfig"></ks-loading-spinner>
    </ng-container>

    <a class="nav-right {{isFirstImage ? 'no-pointer' : ''}}"
       [attr.aria-label]="accessibilityConfig.mainNextImageAriaLabel"
       [tabindex]="isLastImage ? -1 : 0" role="button"
       (click)="onNavigationEvent('right', $event, normalAction, isLastImage)"
       (keyup)="onNavigationEvent('right', $event, normalAction, isLastImage)">
      <div class="inside {{isLastImage ? 'empty-arrow-image' : 'right-arrow-image'}}"
           aria-hidden="true"
           [title]="isLastImage ? '' : accessibilityConfig.mainNextImageTitle"></div>
    </a>
  </div>
</main>
