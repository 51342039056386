import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[slimScroll]'
})
export class SlimScrollDirective implements OnInit {

  private lastHeight = 0;

  private observer = new MutationObserver(() => {

    let newHeight = this.elementRef.nativeElement.scrollHeight;

    if (this.lastHeight !== newHeight) {
      this.elementRef.nativeElement.scrollTo({top: this.elementRef.nativeElement.scrollHeight, behavior: 'smooth'});

      this.lastHeight = newHeight;
    }
  });

  constructor(private elementRef: ElementRef,
              private renderer: Renderer2) {
    this.renderer.addClass(this.elementRef.nativeElement, 'slim-scrollbar');
  }

  ngOnInit() {
    this.observer.observe(this.elementRef.nativeElement, {attributes: true, subtree: true});

  }

}
