import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { Base64 } from 'js-base64';
import { HttpService } from './http.service';
@Injectable({
  providedIn: 'root'
})
export class HelperService {
  constructor(protected httpService: HttpService) {}

  /**
   * Конвертация в транслит
   * @param str
   */
  static convertToTranslit(str: string): string {
    const arrRu = [
      ' ',
      'Я',
      'я',
      'Ю',
      'ю',
      'Ч',
      'ч',
      'Ш',
      'ш',
      'Щ',
      'щ',
      'Ж',
      'ж',
      'А',
      'а',
      'Б',
      'б',
      'В',
      'в',
      'Г',
      'г',
      'Д',
      'д',
      'Е',
      'е',
      'Ё',
      'ё',
      'З',
      'з',
      'И',
      'и',
      'Й',
      'й',
      'К',
      'к',
      'Л',
      'л',
      'М',
      'м',
      'Н',
      'н',
      'О',
      'о',
      'П',
      'п',
      'Р',
      'р',
      'С',
      'с',
      'Т',
      'т',
      'У',
      'у',
      'Ф',
      'ф',
      'Х',
      'х',
      'Ц',
      'ц',
      'Ы',
      'ы',
      'Ь',
      'ь',
      'Ъ',
      'ъ',
      'Э',
      'э'
    ];
    const arrEn = [
      '-',
      'Ya',
      'ya',
      'Yu',
      'yu',
      'Ch',
      'ch',
      'Sh',
      'sh',
      'Sh',
      'sh',
      'Zh',
      'zh',
      'A',
      'a',
      'B',
      'b',
      'V',
      'v',
      'G',
      'g',
      'D',
      'd',
      'E',
      'e',
      'E',
      'e',
      'Z',
      'z',
      'I',
      'i',
      'J',
      'j',
      'K',
      'k',
      'L',
      'l',
      'M',
      'm',
      'N',
      'n',
      'O',
      'o',
      'P',
      'p',
      'R',
      'r',
      'S',
      's',
      'T',
      't',
      'U',
      'u',
      'F',
      'f',
      'H',
      'h',
      'Ts',
      'ts',
      'Y',
      'y',
      '',
      '',
      '',
      '',
      'E',
      'e'
    ];

    for (let i = 0; i < arrRu.length; i++) {
      let reg = new RegExp(arrRu[i], 'g');
      str = str.replace(reg, arrEn[i]);
    }
    return str;
  }

  /**
   * Генерация строки определенной длины
   * @param count
   */
  static createRandomString(count: number = 6): string {
    const chars: string = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz';
    let randomstring: string = '';
    for (let i = 0; i < count; i++) {
      let rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum, rnum + 1);
    }
    return randomstring;
  }

  /**
   * Создание нового файла с содержимым из response и именем файла из Content-Disposition
   * с последующим скачиванием его.
   * @param data
   * @param contentDisposition
   * @param base64Name
   */
  static downloadFile(data: any, contentDisposition: string, base64Name = true): void {
    const blob = new Blob([data], { type: 'text/plain' });
    // Формируем дефолтное имя файла
    let filename = `${(Math.random() * 1e8).toFixed()}_${
      new Date().getFullYear() +
      '-' +
      ('0' + (new Date().getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + new Date().getDate()).slice(-2)
    }`;

    if (contentDisposition && contentDisposition.length > 0) {
      let filenames = contentDisposition.match(/(filename\*=|filename=)\"?(.*)\"?/);
      if (filenames[2]) {
        filenames[2]=filenames[2].replace(/\"/, '')
        filename = decodeURI(filenames[2].replace(/.*''/, ''));
      }
    }

    filename = base64Name ? Base64.decode(filename) : filename;

    saveAs(blob, filename);
  }

    /**
   * Создание нового файла с содержимым из response и именем файла из Content-Disposition
   * с последующим скачиванием его.
   * @param data
   * @param contentDisposition
   * @param base64Name
   */
    static downloadFileTest(data: any, contentDisposition: string, base64Name = true): void {
      // console.log("downloadFileTest",contentDisposition)
      const blob = new Blob([String.fromCharCode(0xFEFF), data], { type: 'text/csv;charset=utf-8' });
      let filename = `${(Math.random() * 1e8).toFixed()}_${
        new Date().getFullYear() +
        '-' +
        ('0' + (new Date().getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + new Date().getDate()).slice(-2)
      }`;

      if (contentDisposition && contentDisposition.length > 0) {
        let filenames = contentDisposition.match(/(filename\*=|filename=)\"?(.*)\"?/);
     
        if (filenames[2]) {
          filenames[2]=filenames[2].replace(/\"/, '')
          filename = decodeURI(filenames[2].replace(/.*''/, ''));
        }
      }
      filename = base64Name ? Base64.decode(filename) : filename;
      saveAs(blob, filename, { autoBom: true });
    
    }





 

    static addUTF8BOMToString(inputString) {
      // Create a Uint8Array containing the UTF-8 BOM (Byte Order Mark).
      const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
    
      // Convert the input string to a Uint8Array.
      const textBytes = new TextEncoder().encode(inputString);
    
      // Combine the BOM and the text bytes into a single Uint8Array.
      const resultBytes = new Uint8Array(bom.length + textBytes.length);
      resultBytes.set(bom, 0);
      resultBytes.set(textBytes, bom.length);
    
      // Convert the combined bytes back to a string.
      const resultString = new TextDecoder('utf-8').decode(resultBytes);
    
      return resultString;
    }
      

    static addUTF8BOMToStringOld(inputString) {
      // Create a Uint8Array containing the UTF-8 BOM (Byte Order Mark).
      const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
    
      // Convert the input string to a Uint8Array.
      const textBytes = new TextEncoder().encode(inputString);
    
      // Combine the BOM and the text bytes into a single Uint8Array.
      const resultBytes = new Uint8Array(bom.length + textBytes.length);
      resultBytes.set(bom, 0);
      resultBytes.set(textBytes, bom.length);
    
      // Convert the combined bytes back to a string.
      const resultString = new TextDecoder('utf-8').decode(resultBytes);
    
      return resultString;
    }
  static expandPolygon(coords: number[][]) {
    if (!coords || !coords.length) {
      return null;
    }
    let polygon = [];

    switch (coords.length) {
      case 1: {
        polygon = this.createPolygonByPoint(coords, 12, 150);
        break;
      }
      case 2: {
        polygon = this.createPolygonByTwoPoint(coords, 150);
        break;
      }
      default: {
        // polygon = this.createPolygonByMoreThreePoint(coords, 150);
        polygon = coords;
      }
    }

    return polygon;
  }

  private static createPolygonByPoint(
    coords: number[][],
    countAngles: number = 12,
    distance: number = 150
  ): number[][] {
    if (coords.length !== 1 || countAngles === 0) {
      return null;
    }

    const azimut = 360 / countAngles;
    const earth = (6371000 * Math.PI) / 180;
    let polygon = [];

    for (let i = 0; i < countAngles; i++) {
      let rad = (azimut * i * Math.PI) / 180;

      let x1 = coords[0][0] + (distance * Math.cos(rad)) / earth;
      let y1 = coords[0][1] + (distance * Math.sin(rad)) / Math.cos((coords[0][0] * Math.PI) / 180) / earth;

      polygon.push([x1, y1]);
    }

    return polygon;
  }

  private static getCenterPolygon(coords: number[][]): number[] {
    if (!coords || !coords.length) {
      return null;
    }

    return [coords.reduce((x, y) => x + y[0], 0) / coords.length, coords.reduce((x, y) => x + y[1], 0) / coords.length];
  }

  private static createPolygonByMoreThreePoint(coords: number[][], distance: number = 150) {
    if (!coords || !coords.length) {
      return null;
    }

    const earth = (6371000 * Math.PI) / 180;
    const center = this.getCenterPolygon(coords);
    const polygon = [];

    for (let i = 0; i < coords.length; i++) {
      let rad = Math.atan2(coords[i][1] - center[1], coords[i][0] - center[0]);

      let x1 = coords[i][0] + (distance * Math.cos(rad)) / earth;
      let y1 = coords[i][1] + (distance * Math.sin(rad)) / Math.cos((coords[i][0] * Math.PI) / 180) / earth;

      polygon.push([x1, y1]);
    }

    return polygon;
  }

  private static createPolygonByTwoPoint(coords: number[][], distance: number = 150) {
    if (!coords || !coords.length) {
      return null;
    }

    const earth = (6371000 * Math.PI) / 180;
    const center = this.getCenterPolygon(coords);
    const polygon = [];

    const rad45deg = (45 * Math.PI) / 180;

    for (let i = 0; i < coords.length; i++) {
      let rad1 = Math.atan2(coords[i][1] - center[1], coords[i][0] - center[0]) + rad45deg;

      let x1 = coords[i][0] + (distance * Math.cos(rad1)) / earth;
      let y1 = coords[i][1] + (distance * Math.sin(rad1)) / Math.cos((coords[i][0] * Math.PI) / 180) / earth;

      polygon.push([x1, y1]);

      let rad2 = Math.atan2(coords[i][1] - center[1], coords[i][0] - center[0]) - rad45deg;

      let x2 = coords[i][0] + (distance * Math.cos(rad2)) / earth;
      let y2 = coords[i][1] + (distance * Math.sin(rad2)) / Math.cos((coords[i][0] * Math.PI) / 180) / earth;

      polygon.push([x2, y2]);
    }
  
    return polygon;
  }


  transformDatePoint(date:Date|""){
    // console.log ("transformDatePoint",date)
    if(!date) return ""
    const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
    const day = date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate();
    return `${day}.${month}.${date.getFullYear()}`;
  }
  transformStringPoint(date:string){
    const dd = +date.substr(0, 2);
    const mm = +date.substr(3, 2);
    const yyyy = +date.substr(6, 4);
    return new Date(yyyy, mm - 1, dd);
  }
}
