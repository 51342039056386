export const whitelistedDomains = [
  "backend-3.tr-portal.ru",
  "develop-backend.tr-portal.ru",
  "develop-backend2.tr-portal.ru",
  "stepan-backend.tr-portal.ru",
  'test.tr-portal.ru',
  "test-backend-3.tr-portal.ru",
  "192.168.201.124:8080", // Андрей
  "192.168.201.167:8080", // Андрей О.
  "192.168.204.201:8080", // 1й тестовый
  "192.168.204.201:8081", // 2й тестовый
  "192.168.204.211:8080", // 3й тестовый
  "192.168.204.211:8081",
   "192.168.204.144:8080",
  "localhost:8080",
  "192.168.204.144:81",
  "192.168.150.103:8080",
  "trback.btlsrv.ru",
  "mobile.tr-portal.ru"
];
