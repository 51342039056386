import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { IComment } from './comment/comment.component';
import { CommentSendStatus } from './sender/sender.component';
import { HttpResponse } from '@angular/common/http';
import { CommentsDataSource } from '../../model/comments-data-source.interface';

@Component({
  selector: 'ks-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommentsComponent implements OnInit {
  /**
   * DataSource для работы с комментариями
   */
  @Input()
  commentsDataSource: CommentsDataSource;

  /**
   * Статус формы компонента sender
   */
  public sendFormStatus: BehaviorSubject<CommentSendStatus>;

  /**
   * Массив с комментариями
   */
  public commentsList: IComment[] = null;

  /**
   * Subject для отписки.
   */
  private ngUnsubscribe = new Subject();

  /**
   * Статус загзузки комментариев.
   */
  public isLoading = false;

  /**
   * Observable для отображения ошибки.
   */
  public loadingError$ = new Subject<string>();

  constructor(private ref: ChangeDetectorRef) {}

  private _id: number;
  private isCommentLoading:boolean=false;
  /**
   * Текущий id фотографии
   * @param value
   */
  @Input()
  set id(value) {
    this._id = +value;
    // При смене фото, отменяем предущие запросы и запрашиваем комментарии по id фотографии.
    this.ngUnsubscribe.next();

    if (this.commentsDataSource) {
      this.getComments(this.id);
    }
  }

  get id() {
    return this._id;
  }

  ngOnInit() {
    this.getComments(this.id);
  }

  /**
   * Получение списка комментариев по photoId
   * @param photoId
   */
  getComments(photoId: number): void {
    this.isLoading = true;
    this.commentsList = null;

    this.commentsDataSource
      .load(photoId)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => {
          this.isLoading = false;
        }),
        catchError(error => {
          this.isLoading = false;
          this.loadingError$.next(error);
          return of();
        })
      )
      .subscribe(data => {
        this.commentsList = <IComment[]>data;
        this.ref.detectChanges();
      });
  }

  /**
   * Отправка сообщения
   * @param message
   */
  sendMessage(message: string) {
    if (!message) return;
    if( this.isCommentLoading) return
    this.isCommentLoading =true;
    this.sendFormStatus.next({ error: '', loading: true });

    this.commentsDataSource
      .insert(this.id, message)
      .pipe(
        catchError(error => {
          this.sendFormStatus.next({ error: error, loading: false });
          this.isCommentLoading =false;
          return of();
        })
      )
      .subscribe((response: HttpResponse<any>) => {
        this.commentsList.push(response.body);

        this.sendFormStatus.next({ error: '', loading: false });
        this.isCommentLoading =false;

        this.ref.detectChanges();
      });
  }

  /**
   * Ускорение перерендеринга.
   * @param index
   * @param item
   */
  trackByFn(index, item) {
    return index;
  }
}
