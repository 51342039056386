import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LocalStorageService } from 'ngx-webstorage';
import { UserService } from '../services';

@Injectable()
export class AuthGuard  {
  constructor(
    private jwt: JwtHelperService,
    private userService: UserService,
    private router: Router,
    private storage:LocalStorageService) {}

  canActivate(): boolean {
    console.log('Сработал canActivate в AuthGuard');

    // let token = localStorage.getItem('access_token');
    //  const token = localStorage.getItem('ngx-webstorage|access_token');
    let token = this.storage.retrieve('access_token');
    if (token || !this.jwt.isTokenExpired(token)) {

      this.router.navigate(['/home']);
      // alert("AuthGuard")
      // alert("return false;")
      return false;
    }
    // alert("return true;")
    return true;
  }


}
