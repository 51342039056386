import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, Scheduler, Subject, asyncScheduler, from, fromEvent, of, scheduled, timer } from 'rxjs';
import { HttpService } from './http.service';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { delay, repeat, repeatWhen, retryWhen, take, takeUntil, timeInterval, timeout, timeoutWith } from 'rxjs/operators';
import { AsyncScheduler } from 'rxjs/internal/scheduler/AsyncScheduler';
import { Action } from 'rxjs/internal/scheduler/Action';
import { timeStamp } from 'console';


export interface ReportsOrder {
  id: number,
  reportId: number,
  reportName : string,
  url: string,
  isDownloaded:0|1,
  statusId: number,
  statusdescription: string,
  createdOn: Date,
  created:string, // "2024-01-09"
  complitedDate: Date,
}

@Injectable({
  providedIn: 'root'
})
export class ReportNotificationService {
  
  public reports$: Subject<ReportsOrder[]> = new Subject()

  public reportsResponse$: Subject<HttpResponse<ReportsOrder[]>> = new Subject()
 
  public reportsInOrder$: Subject<ReportsOrder[]> = new Subject()

  public reportsReady$: Subject<ReportsOrder[]> = new Subject()

  showedOrdersIds=[];

  isListerningNotification: boolean;

  isListerningSubject :Subject<boolean> = new Subject();

  countReqests=-1;
  notifier:Subject<any> =new Subject();
  timeoutId;

  constructor(   
    protected toastr: ToastrService,
    protected httpService: HttpService
    ) { 
      this.reportsInOrder$.subscribe((data)=>{
        this.toastr.info(`Отчет поставлен в очередь (всего ${data.length} в очереди). Посмотреть список очереди можно по <a href='reports/order' target='_blank' > ссылке </a>  или дождитесь уведомления`,"",{enableHtml:true,timeOut:10000})
      })
      this.reportsReady$.subscribe((data)=>{
        data.forEach((report,i)=>{
          let id = 'link' + i;
          this.markAsWiewed(report)
          let toastr =this.toastr.info(`Отчет <b>"${report.reportName}"</b> готов. Нажмите на кнопку чтобы <a href='${report.url}' download class='${id}'  > скачать</a>`,"",{enableHtml:true,timeOut:0,closeButton:true});
          toastr.onTap .pipe(take(1)) .subscribe((data:any)=>{
            // console.log((event.target as HTMLElement).tagName=="A")
            if((event.target as HTMLElement).tagName=="A") {
              console.log (report)
              this.markDownloaded(report)
            }
 
          })
          // this.onReadyReport();
          this.addReportAsShowed(report)
        })
      })
      onstorage = this.onStorage.bind(this)
      this.fetchIsListerningNotification();



      this.notifier.subscribe((data)=>{
        this.getReportNotify().pipe(
          take(1),//отменит подписку,  когда getReportNotify передаст первое значение.
          takeUntil(this.isListerningSubject),//отменит подписку до первого значения, если прекращение прослушки пришло с другой страницы
        ).subscribe((reportOrder:ReportsOrder[])=>{
          if(reportOrder.length){
            let reportsReady = this.filterNotification(reportOrder)
            if(reportsReady.length) this.reportsReady$.next(reportsReady )
            if(this.hasReportsProcessing(reportOrder)) {
              this.countReqests++;
              this.timeoutId =setTimeout(()=>{
                this.notifier.next(true)
              },this.getDelay())
            }
            else this.stopListerning()
          }
          else  
            this.stopListerning()
        })
      })
  }


  hasReportsProcessing(reports:ReportsOrder[]):boolean{
    return reports.some((report)=>{return report.statusId==2})
  }

  addReportAsShowed(report:ReportsOrder){
    this.showedOrdersIds.push(report.id)
  }


  checkReportsInOrder(){
    this.getReportOrder().subscribe((reportOrder:ReportsOrder[])=>{
      this.reports$.next(reportOrder)
      this.reportsInOrder$.next(reportOrder.filter((item)=>{return item.statusId==1 ||  item.statusId==2}))
    })
  }

  getReportOrder(){
    return  this.get('report-service/api/outer-report/all')
  }
  getReportOrderResponse(){
    return  this.getResponse('report-service/api/outer-report/all')
  }

  getReportNotify(){
    // if()
    return  this.httpService.get('report-service/api/outer-report/notify/show')
  }

  loadreports(){
    this.getReportOrderResponse().subscribe((response:HttpResponse<ReportsOrder[]>)=>{
      this.reportsResponse$.next(response)
      this.reports$.next(response.body)
    })
  }

initialization(){
  
  if( !this.getIsListerning()  ) this.checkNotifications()
    else this.startListerning();
}

  checkNotifications(){
        this.getReportNotify().pipe(takeUntil(this.isListerningSubject)).subscribe((reportOrder:ReportsOrder[])=>{
      if(reportOrder.length){
        let reportsReady = this.filterNotification(reportOrder)
        this.reportsReady$.next(reportsReady )
      }
    })
  }

  filterNotification(reports:ReportsOrder[]):ReportsOrder[]{
    let self =this;
    let result =[];
    reports.forEach((report:ReportsOrder)=>{
      if(isFitByDate(report) && isFitByDownloadd(report)) result.push(report)
      else this.markAsWiewed(report)
      
    })
    return result.filter((report:ReportsOrder)=>{return report.statusId==3})

    function isFitByDate(report:ReportsOrder):boolean{
      let created = self.convertStringToDateDash(report.created);
      return (+new Date()  - +created ) <= 1000*60*60*24
    }
    function isFitByDownloadd(report:ReportsOrder):boolean{
      return !report.isDownloaded
    }

  }
  
  convertStringToDateDash(string):Date{ //"16.01.2024 16:39:43"
    const dd = +string.substr(0,2 );
    const mm = +string.substr(3, 2);
    const yyyy = +string.substr(6, 4 );
    return new Date(yyyy, mm - 1, dd);
  }


  public get(path: string, params?): Observable<any> {
    return this.httpService.get(path, params)
  }
  public getResponse(path: string, params?): Observable<any> {
    return this.httpService.getResponse(path, params)
  }

  public getBlob(path: string, body?, params?): Observable<any> {
    return this.httpService.getBlob(path, body,params)
  }

  markDownloaded(data){
    let params = new HttpParams().append('sessionId',data.sessionId)
    this.httpService.post('report-service/api/outer-report/download',{}, params).subscribe((data:any)=>{
    this.loadreports()
  })
  }

  markAsWiewed(data){
    let params = new HttpParams().append('sessionId',data.sessionId)
    this.httpService.post('report-service/api/outer-report/notify/read',{}, params).subscribe((data:any)=>{
    // this.loadreports()
    })
  }

  

  onReqestOrder(){
    if(!this.getIsListerning()) this.startListerning()
  }

  onReadyReport(){
    // console.log ("onReadyReport")
    this.getReportOrder().subscribe((reportOrder:ReportsOrder[])=>{
      this.reports$.next(reportOrder)
      let reportOrderFiltered = reportOrder.filter((item)=>{return item.statusId==1 ||  item.statusId==2})
      if(!reportOrderFiltered.length) this.stopListerning()
    })
  }

  startListerning(delayM:boolean = false){
    this.isListerningNotification =true;
    this.setIsListerningNotification(true);
    this.notifier.next(true)
  }

  stopListerning(){
    this.isListerningNotification =false;
    this.setIsListerningNotification(false);
    this.isListerningSubject.next(false);
    this.countReqests = -1;
    clearTimeout(this.timeoutId)
  }

  stopListerningAfterLogout(){
    this.isListerningNotification =false;
    this.isListerningSubject.next(false);
    this.countReqests = -1;
    clearTimeout(this.timeoutId)
  }

  getIsListerning(){
    return this.isListerningNotification
  }


  fetchIsListerningNotification(){
    this.isListerningNotification = !! JSON.parse(localStorage.getItem("isListerningNotification"))
    if(!this.getIsListerning()) this.isListerningSubject.next(false)
  }

  setIsListerningNotification(boolean: boolean){
    localStorage.setItem("isListerningNotification",JSON.stringify(boolean))
  }

  getDelay(){
    if(this.countReqests ==-1) return 0
    return (10 + 0.03*this.countReqests*10 ) > 60 ? 60*1000 :(10 + 0.03*this.countReqests*10 )*1000
  }


  onStorage(){
    // console.log("onStorage")
    let isListerning =  !! JSON.parse(localStorage.getItem("isListerningNotification"))
    // console.log("onStorage",isListerning ,this.isListerningNotification)
    if(isListerning && ! this.isListerningNotification) {
      this.startListerning();
      return;
    }
    if( !isListerning && ! this.isListerningNotification) {
      this.stopListerning();
      return;
    }
    if(!isListerning && this.isListerningNotification){
      this.getReportOrder().subscribe((reportOrder:ReportsOrder[])=>{
        this.reports$.next(reportOrder)
        let reportOrderFiltered = reportOrder.filter((item)=>{return item.statusId==1 ||  item.statusId==2})
        if(!reportOrderFiltered.length) this.stopListerning()
      })
    }
  }

}
