<ks-plain-gallery [images]="images"
                  [showGallery]="showGallery && isPlainGalleryVisible()"
                  [plainGalleryConfig]="plainGalleryConfig"
                  [accessibilityConfig]="accessibilityConfig"
                  (show)="onShowModalGallery($event)"></ks-plain-gallery>

<ks-background [isOpen]="opened"
               [accessibilityConfig]="accessibilityConfig"></ks-background>

<div id="modal-gallery-wrapper" *ngIf="opened"
     [attr.aria-label]="accessibilityConfig.modalGalleryContentAriaLabel"
     [title]="accessibilityConfig.modalGalleryContentTitle"
     ksClickOutside [clickOutsideEnable]="enableCloseOutside"
     (clickOutside)="onClickOutside($event)">

  <div id="flex-min-height-ie-fix">
    <div id="modal-gallery-container">

      <ks-upper-buttons [currentImage]="currentImage"
                        [buttonsConfig]="buttonsConfig"
                        (delete)="onDelete($event)"
                        (navigate)="onNavigate($event)"
                        (download)="onDownload($event)"
                        (close)="onCloseGallery($event)"
                        (fullscreen)="onFullScreen($event)"
                        (customEmit)="onCustomEmit($event)"
                        (comments)="onShowComments($event)"
                        (rotate)="onRotate($event)"
                        (rotateCss)="onRotateCss($event)"
                        (info)="onShowInfo($event)"
      ></ks-upper-buttons>


      <ks-current-image [id]="id"
                        [images]="images"
                        [currentImage]="currentImage"
                        [isOpen]="opened"
                        [(isOpenInfoBlock)]="openedInfoBlock"
                        [slideConfig]="configSlide"
                        [keyboardConfig]="keyboardConfig"
                        [accessibilityConfig]="accessibilityConfig"
                        [currentImageConfig]="currentImageConfig"
                        (loadImage)="onImageLoad($event)"
                        (changeImage)="onChangeCurrentImage($event)"
                        [commentsDataSource]="commentsDataSource"
                        (close)="onCloseGallery($event)"
                        (selectImage)="checkboxChange($event)"
                        [selectedImages]="selectedImages"
                        [infoTemplate]="infoTemplate"
                        [infoBlockContent]="infoBlockContent"
                        [isCheckable] = "isCheckable"
                        ></ks-current-image>

      <div>
        <ks-dots [images]="images"
                 [currentImage]="currentImage"
                 [dotsConfig]="dotsConfig"
                 [accessibilityConfig]="accessibilityConfig"
                 (clickDot)="onClickDot($event)"></ks-dots>

        <ks-previews [images]="images"
                     [currentImage]="currentImage"
                     [previewConfig]="previewConfig"
                     [accessibilityConfig]="accessibilityConfig"
                     (clickPreview)="onClickPreview($event)"></ks-previews>
      </div>
    </div>
  </div>
</div>
