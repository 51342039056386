<div *ngIf="(formStatus$ | async) as formStatus"
     class="comment-sender">

  <div class="comment-sender__error"
       [ngClass]="{'visible': formStatus.error}"
  >
  {{'gallery.error'  | translate}}
  
    <span class="close-button close-button_error" (click)="closeErrorBlock()">
      <fa-icon [icon]="['fas', 'times']"></fa-icon>
    </span>
  </div>

  <div
    class="comment-sender__textarea"
    [ngClass]="{'empty': isEmpty}">
      <textarea #message
                class="comment-sender__message"
                [placeholder]="placeholder"
                name="message"
                [disabled]="formStatus.loading"
                (focus)="resetAlert()"></textarea>
  </div>

  <div class="comment-sender__button" [ngClass]="{'comment-sender__button_disabled': formStatus.loading}">
    <div (click)="send(message)"
         class="icon-wrapper">
      <ng-container *ngIf="!formStatus.loading; else loading">
        <div>
          <fa-icon class="icon" [icon]="['far', 'paper-plane']"></fa-icon>
        </div>
      </ng-container>
      <ng-template #loading>
        <div class="comments__loading">
          <fa-icon class="tr-spin" [icon]="['fas', 'spinner']"></fa-icon>
        </div>
      </ng-template>

    </div>
  </div>

</div>
