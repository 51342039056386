export class Application {
  id?: number;
  name: string;
  version: string;
  isDefault: boolean;
  isLight: boolean;
  isRequiredUpdate: boolean;
  isActive: boolean;
  distributionPath?: string;
    mobilePackageId?:number;
  constructor(
   name: string = "", 
    version: string ="",  
    isDefault: boolean = false,
    isLight: boolean =false,
    isRequiredUpdate: boolean =false,
    isActive: boolean =true,
    mobilePackageId: number =1 ){
      this.name =name;
      this.version =version;
      this.isDefault =isDefault;
      this.isLight = isLight;
      this.isRequiredUpdate = isRequiredUpdate;
     this.isActive = isActive;
      this.mobilePackageId = mobilePackageId
  }
}
