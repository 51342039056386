interface IVisitPeriodRule {
  id: number;
  name: string;
}

export class VisitPeriodRuleModel implements IVisitPeriodRule {
  public id = null;
  public name = '';
  public visitPlanned: number;

  constructor(data = {}) {
    Object.assign(this, data);
  }
}
